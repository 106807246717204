<template>
  <div class="preview-wrap">
    <div class="water-marks">
      <div class="item" v-for="i in 60" :key="i">
        <OpenData type="userName" :openid="userInfo.realName" />(<OpenData
          type="departmentName"
          :openid="userInfo.departmentName"
        />)
      </div>
    </div>
    <iframe
      id="iframePDF"
      scrolling="yes"
      width="100%"
      height="100%"
      frameborder="0"
      marginheight="0"
      framespacing="0"
      marginwidth="0"
      :src="
        '/pdfview/web/viewer.html?file=' +
        filePath.slice(0, filePath.lastIndexOf('.')) +
        '.pdf'
      "
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import ls from "@/utils/local-storage";
import { currentHost } from "@/utils/request.js";
import { platformConfig } from "@/api/other";
import { wxworkConfig } from "@/api/user";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      userInfo: ls.get("userInfo"),
      filePath: route.query.filePath,
    });

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    let isWxWork = /wxwork/i.test(ua);
    let isDingding = /dingtalk/i.test(ua);
    platformConfig({ site: currentHost }).then((res) => {
      if (res.data.platform == 26) {
        if (isWxWork) {
          console.log("isWxWork");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.search,
            jsTicketType: "2", // 不为空 - 获取企业标识 为空 则为应用标识
          }).then((r) => {
            let d = r.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: d.corpId, // 必填，公众号的唯一标识
              timestamp: d.timestamp, // 必填，生成签名的时间戳
              nonceStr: d.nonceStr, // 必填，生成签名的随机串
              signature: d.signature, // 必填，签名，见附录1
              jsApiList: [
                "checkJsApi",
                "startLiving",
                "replayLiving",
                "agentConfig",
                "invoke",
                "startMeeting",
              ],
              openTagList: ["wx-open-launch-app"],
            });
            wx.ready(() => {
              wxworkConfig({
                url:
                  location.protocol +
                  "//" +
                  location.hostname +
                  location.pathname +
                  location.port +
                  location.search,
              }).then((res) => {
                let d2 = res.data;
                wx.agentConfig({
                  debug: true,
                  corpid: d2.corpId,
                  agentid: d2.agentid,
                  timestamp: d2.timestamp,
                  nonceStr: d2.nonceStr,
                  signature: d2.signature,
                  jsApiList: ["selectExternalContact"],
                  success: function (res) {
                    // 回调
                    console.log("agentConfigSuccess：" + JSON.stringify(res));
                  },
                  fail: function (res) {
                    if (res.errMsg.indexOf("function not exist") > -1) {
                      alert("版本过低请升级");
                    }
                  },
                });
              });
            });
            wx.error((res) => {
              console.log(res, "---");
            });
          });
        } else {
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.port +
              location.search,
          }).then((res) => {
            let d = res.data;
            wx.agentConfig({
              debug: true,
              corpid: d.corpId,
              agentid: d.agentid,
              timestamp: d.timestamp,
              nonceStr: d.nonceStr,
              signature: d.signature,
              jsApiList: ["selectExternalContact"],
              success: function (res) {
                // 回调
                console.log("agentConfigSuccess：" + JSON.stringify(res));
              },
              fail: function (res) {
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert("版本过低请升级");
                }
              },
            });
          });
        }
      } else if (res.data.platform == 31 && isDingding) {
        let dingCorpid = ls.get("dingCorpid");
        let ddInit = window.DTOpenData.init(dingCorpid);
        console.log("ddInit:" + ddInit);
      }
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.preview-wrap {
  height: 100vh;
  overflow: hidden;
  position: relative;
  .water-marks {
    pointer-events: none;
    z-index: 999;
    position: absolute;
    right: 0;
    left: 0;
    top: 36px;
    bottom: 0;
    overflow: hidden;
    .item {
      float: left;
      transform: rotate(-20deg);
      margin: 0 50px 150px 50px;
      font-size: 18px;
      line-height: 40px;
      color: rgba(170, 170, 170, 0.4);
      pointer-events: none;
    }
  }
}
</style>
